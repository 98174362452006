<template>
	<div>

		<div
			v-if="items.length > 0"
		>
			<ul class="hide-pc">
				<li
					v-for="(item, index) in items"
					:key="'item_' + index"
					class="justify-space-between gap-10 box box-shadow mb-10 pa-10"
				>
					<div class="square-100"><img :src="item.pdt_img2" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" /></div>
					<div class="flex-1 text-right items-center ">
						<div class="font-weight-bold mt-10">{{ item.pdt_name }}</div>
						<div class=" mt-10">{{ item.pdt_price | makeComma}}원</div>
						<div class=" mt-10"><button class="btn-danger btn-s size-px-12-m" @click="postDelete(item)">삭제</button></div>
					</div>
				</li>
			</ul>
			<table class="table hide-m">
				<colgroup>
					<col width="120px" />
					<col width="auto" />
					<col width="120px" />
					<col width="180px" />
					<col width="120px" />
				</colgroup>
				<thead>
					<tr>
						<th colspan="2">상품명</th>
						<th>판매가</th>
						<th>등록일</th>
						<th>삭제</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, index) in items"
						:key="'item_' + index"
					>
						<td><img :src="item.pdt_img2" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" /></td>
						<td class="td-left">{{ item.pdt_name }}</td>
						<td>{{ item.pdt_price | makeComma}}원</td>
						<td>{{ item.wDate | transDate }}</td>
						<td><button class="btn-danger" @click="postDelete(item)">삭제</button></td>
					</tr>
				</tbody>
			</table>

			<Pagination

				:program="program"
				:align="'center'"
				:options="item_search"

				@click="getData"

				class="mt-auto pa-10"
			></Pagination>
		</div>
		<Empty
			v-else
		></Empty>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/view/Layout/Empty";
export default {
	name: 'wish'
	,
	components: {Empty, Pagination},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: '관심상품'
				, title: true
				, bottom: true
				, top_line: true
			}
			, items: []
			, item_search: {
				page: 1
				, list_cnt: 10
				, tCnt: 10
			}
		}
	}

	, methods: {
		getData: async function(){
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'member/getWish'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.item_search.tCnt = result.data.tCnt
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}
		}
		, postDelete: async function(item){
			if(confirm("해당 상품을 삭제하시겠습니까?")){
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: 'member/deleteWish'
						,data: {
							uid: item.uid
						}
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>