<template>
	<div
		class="flex-column full-height justify-center bg-base"
	>
		<div class="text-center ptb-30">
			<v-icon class="size-px-48">mdi-cloud-off-outline</v-icon>
			<p class="mt-10 size-px-16">{{  text_none }}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'empty'
		, props: ['text']
		, computed: {
			text_none: function(){
				let t = '등록된 내역이 없습니다.'
				if(this.text){
					t = this.text
				}
				return t
			}
		}
	}
</script>